<template>
    <div class='doctermeet'>
        <div class="doctermeet_main">
            <div class="doctermeet_block" >
                    <div class="doctermeet_block_content">
                        <div class='doctermeet_room'>
                            <div class="doctermeet_room_next">
                                <div class="doctermeet_room_key global_primary_text_color">姓名:&nbsp;</div>
                                <div class="doctermeet_room_value">{{doctorInfo.name}}</div>
                            </div>
                            <div class="doctermeet_room_next">
                                <div class="doctermeet_room_key global_primary_text_color">医院:&nbsp;</div>
                                <div class="doctermeet_room_value">{{doctorInfo.hospital}}</div>
                            </div>
                        </div>
                        <div class="doctermeet_room" @click='inInfo'>
                            <div class="doctermeet_room_commit">
                                <div class="doctermeet_room_key global_primary_text_color">个人信息&nbsp;<span>({{ isComplete ? '已提交' : '未提交'}})</span></div>
                                <div><i class='el-icon-arrow-right' style='margin-left:1vw;font-weight:600;'></i></div>
                            </div>
                            <div class="doctermeet_room_update_key">更新时间</div>
                            <div class="doctermeet_room_update_value global_primary_text_color">{{getShowTime()}}</div>
                        </div>
                    </div>
                </div>
            <div class='doctermeet_title_second global_primary_text_color'>会议列表({{doctorInfo.count}})</div>
            <div class="doctermeet_list">
              <ListScroll :url='$urls.admin.doctorlist' ref='ListScroll' :select='search'>
                <template  v-slot:listItem="{citems}">
                    <div class="doctermeet_content" :class='{showColor:isShowColor(citems)}' >
                        <div class='doctermeet_main_block' >
                            <div class='doctermeet_detail_key global_primary_text_color'>{{citems.status == 0 ? "会议日期":"会议编号"}}</div>
                            <div class='doctermeet_detail_date' v-if='citems.status == 0'>
                                <div class='doctermeet_detail_date_day'>{{$tools.getDate(citems.start_time,"yyyy-MM-dd")}}</div>
                                <div class='doctermeet_detail_date_time'>{{$tools.getDate(citems.start_time,"hh-mm-ss")}}</div>
                            </div>
                            <div class='doctermeet_detail_date' v-else>
                                <div class='doctermeet_detail_date_day'>{{citems.meeting_number}}</div>
                            </div>
                            <div class='doctermeet_detail_status' >
                                {{citems.status ? "已召开":'待召开'}} <i class='el-icon-arrow-right'></i>
                            </div>
                        </div>
                        <div class='doctermeet_btn_group' :class='{docter_btn_bottom:!(config.pay_status.includes(citems.pay_status))}'>
                            <el-button type="primary" size='mini'  @click.native='previewCourseware(citems)' v-if="citems.courware_id"  round>课件预览</el-button>
                            <el-button type="primary" size='mini'  @click.native='openMeetDetail(citems)' v-if='citems.status == 0' round>{{ citems.status == 0 ? '信息修改': '信息查看' }}</el-button>
                            <el-button type="primary" size='mini' v-if='citems.status !== 2' @click.native='inMeet(citems)' round>进入会议</el-button>
                            <el-button type="primary" size='mini' v-if='citems.status == 2 && !citems.sign_status' @click.native='sendSignMessage(citems)' round>协议签署提醒</el-button>
                            <el-button type="info" size='mini' v-if='citems.status == 2 && citems.sign_name'  round>协议已签署</el-button>
                            <el-button :type="`${citems.pay_status == -1 ? 'primary':'info'}`" @click.native='openDoctorInfo(citems)' size='mini'  v-if='citems.pay_status' round>{{ $config.types.pays[citems.pay_status] }}</el-button>
                        </div>
                        <div class='doctermeet_pay_detail global_primary_text_color' v-show='citems.pay_status !== 0 && (config.pay_status.includes(citems.pay_status) || true)'>{{ getErrMsg(citems)}}</div>
                    </div>
                </template>
              </ListScroll>
            </div>
            <div class='meetapply' >
                <el-button type="primary" @click.native='createMeet' round>
                    <img class='meetapplyicon' :src='`${require("@/assets/images/meetopera.png")}`'>
                    <span>新建会议</span>
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import  ListScroll from '@/components/unit/ListScroll'
export default {
  name: 'doctermeet',
  data () {
    return {
        search:{
          id:'',
          doctorId:'',
        },
        config:{
            pay_status:[40,-1],
            success_code:40,
            docter:{},
            meets:[]
        },
        doctorInfo:{},
    }
  },
  created(){
    document.getElementsByTagName('title')[0].innerHTML = '会议状态'
    this.search = {
        ...this.$config.search,
        id:this.$route.query.doctorId,
        doctorId:this.$route.query.doctorId
    }
    this.loadData();

  },
  computed:{
    isComplete(){
      return this.doctorInfo.id_card_front
    }
  },
  methods:{
        loadData(){
            let url = this.$tools.getURL(this.$urls.admin.doctor,{id:this.search.doctorId})
            this.$axios.get(url).then((res)=>{
                console.log(res)
                this.doctorInfo = {
                    ...res.data,
                    count:res.count||0
                }
            })
        },
        isShowColor(data){
            return data.status==1
        },
        inInfo(data){
            this.$router.push({
                path:'/docterdetail',
                query:{
                  doctorId:this.search.doctorId,
                }
            })
        },
        previewCourseware(data){
          this.$router.push({
                path:'/preview',
                query:{
                  select:0,
                  coursewareId:data.courware_id
                }
            })
        },
        openDoctorInfo(item){
            if(item.pay_status !== -1) return;
            this.inInfo()
        },
        openMeetDetail(data){
            this.$router.push({
                path:'/meetinfo',
                query:{
                  doctorId:this.search.doctorId,
                  meetId:data.id
                }
            })
        },
        inMeet(item){
            this.$router.push({
                path:item.meeting_url.split('#')[1],
                query:{
                    role:0
                }
            })　
        },
        createMeet(){            
            if(!this.doctorInfo.openid) {
                this.$tips.error({text:`医生暂未注册,请先提醒医生注册`})
                return ;
            }
            if(!this.isComplete) {
                this.$tips.error({text:`请先完善${this.doctorInfo.name || ''}医生个人信息`})
                return ;
            }

            this.$router.push({
                path:'/meetinfo',
                query:{
                  doctorId:this.search.doctorId
                }
            })
        },
        getErrMsg(item){
            let text = item.pay_status === this.config.success_code ? '付款日期' : '反馈日期'
            return `${text}: ${item.pay_time} ${item.pay_err_reason}`
        },
        getShowTime(){
            let time = this.doctorInfo.update_time || this.doctorInfo.create_time || '';
            return this.$tools.getDate(time,"yyyy-MM-dd")
        },
        sendSignMessage(item){
            if(this.isLoading) return;
            this.isLoading = true;
            let url = this.$tools.getURL(this.$urls.admin,sign,{id:this.search.doctorId,meet_id:item.id})
            this.$axios.get(url).then((res)=>{
                setTimeout(()=>{ this.isLoading = false},1000)
                if(res.errcode) return;
                this.$tips.success({text:'协议签署提醒成功'})
            })
        },
  },
  components:{
    ListScroll
  }
}
</script>
<style >
.doctermeet{
    height:100%;
    width:100%;
    position: relative;
    padding:0 4vw;
    background:#fff;
}
.doctermeet_main{
  display: flex;
  flex-direction: column;
  height:100%;
}
.doctermeet_title{
    height:10vw;
    display: flex;
    align-items: center;
    font-weight: 500;
    color:#81304B;
    /* font-size:4.4vw; */
}
.doctermeet_banner{
    display: flex;
    height:10vw;
}
.doctermeet_banner_block{
    flex:1 0 0;
}
.doctermeet_banner_content {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    color:#b3b3b3;;
}
.doctermeet_banner_line{
    height:100%;
    flex:4vw 0 0;
    display: flex;
    align-items: center;
}
.doctermeet_banner_line span{
    display: inline-block;
    height:50%;
    width:1px;
    background:#000;
}
.doctermeet_title_second{
    flex:12vw 0 0;
    display: flex;
    align-items: center;
    font-size:4vw;
    font-weight: 500;;
    /* margin-bottom:4vw; */
    border-bottom:1px solid #cfcfcf;
}
.doctermeet_block{
    display: flex;
    flex:24vw 0 0;
    border-bottom:1px solid #cfcfcf;
    /* border-radius: 2vw; */
    margin-bottom:4vw;
    font-size:4vw;
    font-weight: 400;
}
.doctermeet_block_img{
    height:100%;
    flex:12vw 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.doctermeet_block_img img{
    height:8vw;
    width:8vw;
    border-radius:50%;
}
.doctermeet_block_content{
    flex:1 0 0;
    display: flex;
    align-items: center;
}
.doctermeet_room{
    display:flex;
}
.doctermeet_room:nth-child(1){
    flex:1 0 0 ;
    display: flex;
    flex-direction: column;
}
.doctermeet_room:nth-child(2){
    flex:40vw 0 0 ;
    display: flex;
    flex-direction: column;
}
.doctermeet_room_key{
    font-weight: 400;
    white-space: nowrap;
}
.doctermeet_room_commit{
    display: flex;
    /* padding:2vw 0; */
}
.doctermeet_room_update_key{
    padding:1vw 0;
}
.doctermeet_room_next{
    display: flex;
    padding:2vw 0;
}
.doctermeet_room_next .doctermeet_room_key{
    font-weight: 500;;
}
.doctermeet{
    font-size:4vw;
    display: flex;
    flex-direction: column;
    height:100%;
}
.doctermeet .meetting{
    flex:1;
    overflow: hidden;
}
.doctermeet .authname{
    height:14.4vw;
    background:#ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:3.6vw;
    line-height: 5vw;
}
.doctermeet .authname div:first-child{
    color:#000;
    font-size:3.6vw;
    font-weight: 700;
}
.doctermeet .authname div:last-child{
    color:#999;
    font-size:3.2vw;
}
.doctermeet .meetshareinfo{
    position: absolute;
    width: 42.66vw;
    height: 26.66vw;
    top:-28.4vw;
    left:-13.33vw;
    height:24.8vw;
    background:#ececec;
    border-radius:2vw;
    padding: 3.2vw 6.4vw;
    display: flex;
    flex-direction: column;
    font-size:3.6vw;
    font-weight: 500;
    z-index:1;
}
.doctermeet .meetshareInfoBg{
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:100%;
}
.doctermeet .meetshareinfo:after{
    content:'';
    position: absolute;
    left: calc(50% - 4vw);
    bottom:-7vw;
    display: inline-block;
    border-width:4vw;
    border-style: solid;
    border-top-color: #ececec;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.doctermeet .meetshareinfo div:first-child{
    border-bottom:1px solid #ddd;
}
.doctermeet .meetshareinfo div{
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.doctermeet .meetbtngroup{
    flex:12vw 0 0 ;
    display: flex;
    height:12vw;
    font-size:3.2vw;
    align-items: center;
    font-weight: 700;
    border-bottom:2px solid #ddd
}
.doctermeet .meetcurbtn{
    flex-grow: 1;
    text-align: center;
    height:100%;    
    display: flex;
    justify-content: center;
}
.doctermeet .meetareaname{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.doctermeet .meetting{
    padding:1.2vw 5.6vw;
}
.doctermeet .meetting h4{
    height:11.6vw;
    line-height:11.6vw;
    border-bottom:1px solid #ddd;
}
.doctermeet .contcontent{
    margin-top:4vw;
    padding:5.6vw 10.1vw 5.6vw 4.26vw;
    background:#f6f6f6;
    border-radius: 4vw;
    position: relative;
}
.doctermeet .meetrow{
    min-height:4vw;
    padding:1vw 0;
    display: flex;
    align-items: flex-start;
    position:relative;

}
.meetrowend{
    display: flex;
    align-items: center;
}
.doctermeet .meetoperashare{
    /* margin-top:1vw; */
    /* padding-bottom:-4vw; */
    position: absolute;
    right:5vw;;
    top:-2vw;
}
.doctermeet .meetrow span{
    vertical-align: middle;
}
.doctermeet .meetrow span:first-child{
    display: inline-block;
    min-width:18.4vw;
    color:#666;
}
.doctermeet .meetrow>span:last-child{
    font-size:3.6vw;
    /* padding:.3vw; */
}
.doctermeet .meetrow .meetinmeet{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width:22.4vw;
    height:7.6vw;
    background:#f3edef;
    border:1px solid #81304B;
    color:#81304B;
    text-align: center;
    border-radius:11.2vw;
    font-weight: 500;
    font-size:3.73vw;
    padding: 0 1.5vw 0 3.2vw;
    line-height: 7.6vw;
    position: relative;

}
.doctermeet .meetrow .meetinmeet:last-child{
    margin-left:4vw;
    padding:0 2vw;
}
.doctermeet .meetrow .meetinmeet span{
    min-width:0;
    color:#81304B;
    vertical-align: middle;
    font-weight: 500;
}
.doctermeet  .meetinmeet i{
    vertical-align: middle;
}
.doctermeet  .meetrighticon{
    height:100%;
    width:8vw;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#ccc;
    font-size:2.8vw;
}

.doctermeet .meetstarttable{
    position: relative;
    padding:2.8vw 0;
}
.doctermeet .meettablethead{
    font-size:3.6vw;
    display: flex;
    align-items: center;
    padding-right:2vw;
    height:9.2vw;
    color:#999;
    border-bottom:1px solid #ddd;

}
.doctermeet .meettwo div {
    width: 100% !important;
}

.doctermeet .meettablethead div,.doctermeet .meettabletr div{
    flex-grow: 1;
    text-align: center;
    width:25%;
    padding-right:2vw;
}
.doctermeet .meettablethead .meettwo,.doctermeet .meettabletr .meettwo{
    flex:3 0 0;
}
.doctermeet .meettablethead .meetone,.doctermeet .meettabletr .meetone{
    flex:2 0 0;
    word-break: break-all;
    text-align: center
}
.doctermeet .meettabletr .meettwo>div:last-child{
    color:#999;
}
.doctermeet .meettabletbody{
    position: relative;
    padding-right:2vw;
    font-size:3.2vw;
    border-bottom:1px solid #ddd;
}
.doctermeet .meetloadingtext{
    width: 100%;
    font-size: 3.2vw;
    text-align: center;
    padding:0 0 4vw;
}

.doctermeet .meettabletbody:last-child{
    border:none
}
.doctermeet .meettabletr{
    min-height:14.8vw;
    display: flex;
    align-items: center;
}
.doctermeet  .meetstartrighticon{
    height:100%;
    width:4vw;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#ccc;
    font-size:3.2vw;
}
.doctermeet .meetapply{
    flex:15.4vw 0 0;
    /* padding:10.8vw 0vw; */
    /* position: absolute;
    bottom:0; */
    padding:0 4vw;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.doctermeet .meetapply button{
    height:9.8vw;
    border-radius:6.4vw;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:4vw;
    /* font-weight: 700; */
    margin:none;
    padding:0 !important;
}
.doctermeet .meetapply button>span img,.doctermeet .meetapply button>span span{
    vertical-align: middle;
}
.doctermeet .meetapplyicon{
    width:4.26vw;
    height:4.26vw;
}
.doctermeet_list{
  flex:1 0 0;
}
.doctermeet_main_block{
    display: flex;
    padding:4vw 0;

}
.doctermeet_content{
    border-bottom:1px solid #cfcfcf;
}
.doctermeet_detail_key{
    flex:20vw 0 0;
    font-weight: 400;;
}
.doctermeet_detail_date{
    flex:1 0 0;
    display: flex;
    justify-content: space-between;
}
.doctermeet_detail_status{
    flex:30vw 0 0;
    text-align: right;
    font-weight: 400;;
}
.doctermeet .showColor{
    color:#9f9f9f
}
.doctermeet .doctermeet_btn_group{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.docter_btn_bottom{
    margin-bottom:4vw;
}
.doctermeet_pay_detail{
    width:100%;
    margin-bottom:2vw;
    padding:2vw 0;
    font-size:3.2vw;
    line-height: 4.8vw;
    white-space: pre-wrap;
    text-align: left;
}
.doctermeet .doctermeet_btn_group>div:last-child button{
    margin-left:2vw;
    font-size:3.6vw;
}

</style>